@font-face {
    font-family: "Karrik";
    src: url("KarrikRegular.woff") format('woff');
    font-display: block;
}

html {
    scroll-behavior: smooth;
}

body {
    background: linear-gradient(222deg, #212121 0%, hsl(0, 0%, 2%) 100%) no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    margin: 0;
}

a {
    text-decoration: none;
}

.center {
    display: grid;
    place-items: center;
    overflow: hidden;
}

.subscriber {
    height: 100%;
    overflow: hidden;
}