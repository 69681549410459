.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 50px;
    width: -webkit-fill-available;
}

.header-link {
    font-family: "Karrik";
    color: #A370FA;
    text-decoration: none;
}

.header-lite {
    display: grid;
    place-items: center;
    padding: 50px;
}

@media only screen and (max-width: 360px) {
    .header {
        flex-direction: column;
        padding: 32px;
    }

    button {
        display: none;
    }
  }