.footer {
    width: 100%;
    text-align: center;
    margin: 164px 0 40px;
    font-family: "Karrik";
    color: #552620;
}

.footer-link {
    color: #552620;
}