.ellipse {
    width: 200%;
    height: 1600px;
    max-height: 1600px;
    background: linear-gradient(306deg, #ffbb00 0%, hsl(0, 88%, 68%) 100%);
    border-radius: 1400px / 500px;
    margin: -88px;
    z-index: -1;
    overflow-x: hidden;
    padding: 0 0 48px 0;
}

.detailsContainer {
    display: grid;
    place-items: center;
    margin-top: -1900px;
    width: 100%;
    overflow: hidden;
}

.listContainer {
    display: grid;
    place-items: center;
    padding: 40px;
    max-width: 860px;
}

.featureList {
    display: grid;
}

.featureList-subhead {
    font-family: "Karrik";
    font-size: 28px;
    color: #552620;
    padding-left: 16px;
    place-self: baseline;
}

.featureList-li {
    font-family: "Karrik";
    font-size: 28px;
    color: #552620;
    padding-left: 16px;
}

.featureList-description {
    font-family: "Karrik";
    font-size: 18px;
    color: #552620;
    opacity: .65;
    margin: 8px 0 32px;
    padding-left: 16px;
}

li::marker {
    content: url("data:image/svg+xml;charset=UTF-8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' height='18' width='18'><path d='M17.0421 0.40831C18.0593 1.12201 18.3054 2.5252 17.5917 3.54243L8.12015 17.0423C7.69896 17.6426 7.01165 18 6.27832 18C5.545 18 4.85768 17.6426 4.43649 17.0423L0.408325 11.3011C-0.305382 10.2839 -0.0593457 8.88071 0.957863 8.167C1.97507 7.45328 3.37826 7.69932 4.09196 8.71654L6.27828 11.8326L13.908 0.9579C14.6217 -0.0593297 16.0249 -0.305389 17.0421 0.40831Z' fill='%23552620'/></svg>");
  }

@media only screen and (max-width: 768px) {
    .detailsContainer {
        margin-top: -1900px;;
    }
    
    .listContainer {
        padding: 24px 24px 24px 4px;
    }
    
    .featureList-li,
    .featureList-subhead {
        font-size: 24px;
    }

    .featureList-description {
        font-size: 16px;
    }
    .detailsContainer::after {
        height: 250%;
    }
}

@media only screen and (max-width: 475px) {
    .detailsContainer {
        margin-top: -1800px;;
    }
}