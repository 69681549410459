.hero {
    display: grid;
    place-items: center;
    padding: 24px;
    margin: 0;
}

.hero:hover > .app-ui {
    transform: skew(0deg, 0deg) scale(1.1);
}

.heroTitle {
    font-family: "Karrik";
    font-size: 56px;
    letter-spacing: -.75px;
    text-align: center;
    line-height: 1.2em;
    color: white;
    margin: 0;
    max-width: 640px;
}

.heroDescription {
    font-family: "Karrik";
    font-size: 18px;
    letter-spacing: -.5px;
    text-align: center;
    line-height: 1.6em;
    color: #929292;
    margin: 32px 0 0 0;
    max-width: 640px;
}

.heroTitle-lineThrough {
    font-family: "Karrik";
    text-decoration: line-through;
    font-size: 50px;
    letter-spacing: .02em;
    text-align: center;
    line-height: 1.2em;
    color: white;
    opacity: .25;
    margin: 0;
}

.app-ui {
    display: grid;
    place-items: center;
    z-index: -1;
    margin-top: 5rem;
    width: 75%;
    transform: skew(-1.75deg, -.5deg);
    filter: drop-shadow(0 0 .75rem rgba(255, 142, 56, .90));
    transition: ease-in-out 1s;
}

.mockup {
    width: 100%;
    border-radius: 24px;
}

@media only screen and (max-width: 768px) {
    .heroTitle,
    .heroTitle-lineThrough {
        font-size: 48px;
    }

    .app-ui {
        width: 90%;
    }
}
