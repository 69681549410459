input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  }

.formContainer {
  display: flex;
  width: fit-content;
  flex-direction: row;
  background-color: white;
  border-radius: 40px;
  box-shadow: 0px 8px 24px 8px rgba(0, 0, 0, 0.15);
  padding: 48px;
  gap: 16px;
  transition: 0.25s;
  z-index: 1;
  will-change: transform;
}

.formContainer:hover {
  box-shadow: 0px 8px 32px 16px rgba(0, 0, 0, 0.25);
  transform: scale(1.05);
}

.formContainer-dark {
  display: flex;
  width: fit-content;
  flex-direction: row;
  background-color: #552620;
  border-radius: 40px;
  box-shadow: 0px 8px 24px 8px rgba(0, 0, 0, 0.15);
  padding: 48px;
  gap: 16px;
  transition: 0.25s;
  z-index: 1;
  will-change: transform;
  scroll-margin: 200px;

}

.formContainer-dark:hover {
  box-shadow: 0px 8px 32px 16px rgba(0, 0, 0, 0.25);
  transform: scale(1.05);
}

.emailInput {
  padding: 24px;
  border-radius: 12px;
  border: none;
  background-color: #ebebeb;
  font-family: "Karrik";
  font-size: 16px;
}

.emailInput:focus {
  outline: none;
  background-color: #d6d6d6;
}

.submitButton {
  display: flex;
  justify-content: center;
  padding: 24px;
  border-radius: 12px;
  background-color: #8138ff;
  font-family: "Karrik";
  font-size: 16px;
  letter-spacing: -.25px;
  color: white;
  cursor: pointer;
  outline: none;
  border: none;
  transition: 0.3s;
  text-align: center;
  white-space: normal;
}

.submitButton:hover {
  background-color: #4a14a9;
}

.submitButton:focus {
  background-color: #3c1188;
  outline: none;
}

.submitButton:active {
  outline: none;
}

.submitButton-dark {
  display: flex;
  justify-content: center;
  padding: 24px;
  border-radius: 12px;
  background-color: #ff8d38;
  font-family: "Karrik";
  font-size: 16px;
  color: #552620;
  cursor: pointer;
  outline: none;
  border: none;
  transition: 0.3s;
  text-align: center;
  white-space: normal;
  min-height: 68px;
}

.submitButton-dark:hover {
  background-color: #eb6600;
}

.submitButton-dark:focus {
  background-color: #c25705;
  outline: none;
}

.submitButton-dark:active {
  outline: none;
}

@media only screen and (max-width: 768px) {
  .formContainer,
  .formContainer-dark {
    flex-direction: column;
    width: 70vw;
    padding: 40px;
  }
}
